import React, { useEffect } from "react";

import Header from "../Header";
import MainNavMenu from "../../Menus/MainNavMenu";
import useShowNavStore from "../../../sp-state/useShowNavStore";
import useWindowSize from "../../../hooks/useWindowSize";

import styles from "./HeaderFullMenu.module.scss";
import LogoContainer from "./LogoContainer";
import PrimaryNavMenuWrapper from "@components/RedesignComponents/BuilderWrappers/PrimaryNavMenu/PrimaryNavMenu";

type HeaderFullMenuType = {
  noSticky?: boolean;
  page?: MainLayoutTypes.PageVariantType;
};
/**
 * ``HeaderFullMenu:`` This component is used across all site where pages need a full menu
 * - ``<Header>`` wraps content
 *  * - ``<LogoContainer>`` wraps logo and mobile button
 *  * - ``<MainNavMenu>`` contains full navigation
 * - ``</Header>``
 */
const HeaderFullMenu: React.FC<
  React.DetailedHTMLProps<
    React.AllHTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > &
    HeaderFullMenuType
> = ({ noSticky, page, ...rest }) => {
  const showNav = useShowNavStore(state => state.showNav);
  const resetShowNav = useShowNavStore(state => state.resetShowNav);
  const { width: windowWidth } = useWindowSize();
  const headerFullMenuStyles = `${styles.headerFullMenu} ${
    showNav && styles.showNav
  }`;

  useEffect(() => {
    if (showNav && windowWidth >= 767) {
      resetShowNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowWidth]);

  useEffect(() => {
    return () => resetShowNav();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <PrimaryNavMenuWrapper />;
};

export default HeaderFullMenu;
